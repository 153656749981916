<template>
	<div id="about">
		<Header title="" description="" />
		<div class="relative z-50 -top-24 md:-top-52 2xl:-top-60 -mb-24 md:-mb-48">
			<div class=" container mx-auto px-8 sm:px-16 md:px-20 lg:px-28">
				<img loading="lazy" src="../assets/about_img.png" alt="" class="w-full object-cover" />

				<h1 class="text-footerBg text-3xl mb-5 mt-8  md:mt-20">
					<span>{{ $t('about.h1') }}</span>
					<span class="bg-info w-20 my-2 h-1 rounded-lg block "></span>
				</h1>

				<p class="text-lg font-medium text-newsDescription my-5">{{ $t('about.p') }}</p>

				<div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 lg:gap-20 my-20">
					<img loading="lazy" src="../assets/image 15.png" alt="" class="w-full object-cover" />
					<div class="py-5">
						<h3 class="text-footerBg text-2xl mb-5 ">
							<span>{{ $t('about.activity_Title') }}</span>
							<span class="bg-info w-12 my-2 h-1 rounded-lg block "></span>
						</h3>
						<p class="text-xl text-newsDescription font-medium my-2">{{ $t('about.activity') }}</p>
					</div>
				</div>

				<div class="w-full md:w-3/4 mx-auto">
					<h1 class="text-footerBg text-2xl my-5">
						<span>{{ $t('about.sanycate_terms') }}</span>
						<span class="bg-info w-12 my-2 h-1 rounded-lg block "></span>
					</h1>
					<ul
						class="list-decimal text-lg text-newsDescription tracking-wide font-medium px-5"
						v-html="$t('about.Term_content')"
					></ul>
				</div>

				<div class="w-full md:w-3/4 mx-auto mt-12">
					<h1 class="text-footerBg text-2xl my-5">
						<span>{{ $t('about.constitution') }}</span>
						<span class="bg-info w-12 my-2 h-1 rounded-lg block "></span>
					</h1>
					<div class="mt-5 border border-consituationBorder p-5 w-full flex item-center justify-between">
						<a rel="noreferrer" href="https://api.llms.ly/storage/Dostor/Dostor.pdf?fbclid=IwAR1dWoKseuoqzWkduG1-Nk8B6IGQRFKpYCPgGx0j88IAN9Aaz9T16Hnxr9I" target="_blank"  download class="text-newsText text-lg hover:underline">{{$t('about.contlabel')}}</a>
						<img class="object-cover" src="../assets/pdf.svg" alt="" loading="lazy" />
					</div>
				</div>


			</div>
		</div>
	</div>
</template>
<script>
import Header from '@/components/layout/Header';

export default {
	name: 'About',
	components: {
		Header,
	},
};
</script>
